const initUserRoles = () => {
  const roleElement = document.querySelector('#user_role_name');
  const officeIdContainer = document.querySelector('.office-id-container');
  const officeIdElement = document.querySelector('#user_office_id');

  if (!roleElement) {
    return;
  }

  roleElement.addEventListener('change', (e) => {
    if (e.target.value.startsWith('employsure_law')) {
      officeIdContainer.classList.remove('d-none');
    } else {
      officeIdContainer.classList.add('d-none');
      officeIdElement.value = '';
    }
  });
};

export default initUserRoles;
