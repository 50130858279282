import qs from 'query-string';

const initPaymentsFilter = () => {
  const form = document.querySelector('#payments-filters');

  if (!form) {
    return;
  }

  const redirect = (newParam) => {
    const params = qs.parse(window.location.search);
    window.location = `/payments?${qs.stringify({ ...params, ...newParam })}`;
  };

  const typeElement = document.querySelector('#type-payment-filter');

  typeElement.addEventListener('change', (event) => {
    redirect({ type: event.target.value });
  });

  const actionElement = document.querySelector('#action-payment-filter');

  actionElement.addEventListener('change', (event) => {
    redirect({ payment_action: event.target.value });
  });
};

export default initPaymentsFilter;
