const readURL = (fileInput, avatarElement) => {
  if (fileInput.files && fileInput.files[0]) {
    const reader = new FileReader();

    reader.onload = function (e) {
      avatarElement.style.backgroundImage = `url(${e.target.result})`;
    };

    reader.readAsDataURL(fileInput.files[0]);
  }
};

const initAvatarFileUpload = () => {
  const fileInput = document.querySelector('#user_avatar');
  const avatarElement = document.querySelector('.avatar-profile');

  if (!fileInput) {
    return;
  }

  fileInput.addEventListener('change', () => {
    readURL(fileInput, avatarElement);
  });
};

export default initAvatarFileUpload;
