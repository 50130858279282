import moment from 'moment';
import 'moment-timezone';

// Eg: 01 Jan
const DEFAULT_LOCALIZED_DATE_FORMAT = 'lll';
moment.locale(['en-AU', 'en-NZ']);

// This function reads all the elements in the page with data-date and will replace the inner HTML
// with the localized version of the date given.
//
// Examples:
// <span data-date="2019-12-11T00:48:24.156340000Z"></span>
// #=> returns "11 Dec 2019 12:48 AM"
//
// <span data-date="2019-12-11T00:48:24.156340000Z" data-relative="true"></span>
// #=> returns something like "2 days ago"
//
// <span data-date="2019-12-11T00:48:24.156340000Z" data-format="DD MMMM"></span>
// #=> returns 11 December
const initLocalizedDates = () => {
  const dateElements = document.querySelectorAll('*[data-date]');
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';

  Array.from(dateElements).forEach((dateElement) => {
    const timestamp = dateElement.dataset.date;
    const relative = dateElement.dataset.relative || false;
    let newDate = moment.tz(timestamp, tz);

    if (relative) {
      newDate = newDate.fromNow();
    } else {
      const format = dateElement.dataset.format || DEFAULT_LOCALIZED_DATE_FORMAT;
      newDate = newDate.format(format);
    }

    dateElement.innerHTML = newDate;
  });
};

export default initLocalizedDates;
