import railsUjs from '@rails/ujs';
import 'bootstrap';

railsUjs.start();

import initAvatarFileUpload from '../components/avatar-file-upload';
import initCheckboxes from '../components/table-checkboxes';
import initLocalizedDates from '../components/localized-dates';
import initCopyClipboard from '../components/copy-clipboard';
import initDownloadRecoveryCodes from '../components/download-recovery-codes';
import initTooltips from '../components/tooltips';
import initTomnavMobile from '../components/topnav-mobile';
import initUserRoles from '../components/user-roles';
import initPaymentsFilter from '../components/payments-filter';
import initClipboard from '../components/clipboard';

(function () {
  document.addEventListener('DOMContentLoaded', () => {
    initAvatarFileUpload();
    initCheckboxes();
    initLocalizedDates();
    initCopyClipboard();
    initDownloadRecoveryCodes();
    initTooltips();
    initTomnavMobile();
    initUserRoles();
    initPaymentsFilter();
    initClipboard();
  });
})();
