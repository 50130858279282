function initDownloadRecoveryCodes() {
  const element = document.querySelector('#download-recovery-codes');

  if (!element) {
    return;
  }

  const codes = element.dataset.text.replace(/-/gi, '\r\n');
  const filename = element.dataset.filename;

  // IE doesn't support href with data:text.
  if (window.navigator.msSaveOrOpenBlob) {
    const blobObject = new Blob([codes]);

    element.addEventListener('click', () => {
      window.navigator.msSaveOrOpenBlob(blobObject, filename);
    });
  } else {
    element.setAttribute('href', `data:text/plain;charset=utf-8,${codes}`);
    element.setAttribute('download', filename);
  }
}

export default initDownloadRecoveryCodes;
