const initCheckboxes = () => {
  const selectAllElements = document.querySelectorAll('.select-all');
  const tableCheckboxElements = document.querySelectorAll('.table-checkbox');

  if (selectAllElements.length == 0 || tableCheckboxElements.length == 0) {
    return;
  }

  const selectedIds = {};

  const updateSelected = (table) => {
    const tableId = table.getAttribute('id');
    const selectAllElement = table.querySelector('.select-all');
    const bulkActionsElement = document.querySelector(`.action-buttons[data-table="${tableId}"]`);
    const selectedIdsElement = document.querySelector(`.selected-ids[data-table="${tableId}"]`);
    const checkboxElements = table.querySelectorAll('.table-checkbox');

    selectedIdsElement.value = selectedIds[tableId];

    if (selectedIds[tableId].length == 0) {
      selectAllElement.indeterminate = false;
      selectAllElement.checked = false;
      bulkActionsElement.classList.add('d-none');
    } else {
      if (selectedIds[tableId].length == checkboxElements.length) {
        selectAllElement.indeterminate = false;
        selectAllElement.checked = true;
      } else {
        selectAllElement.indeterminate = true;
        selectAllElement.checked = false;
      }
      bulkActionsElement.classList.remove('d-none');
    }
  };

  const changeTableCheckboxStatus = (checkboxes, status) => {
    checkboxes.forEach((element) => {
      element.checked = status;
    });
  };

  tableCheckboxElements.forEach((element) => {
    const table = element.closest('table');
    const tableId = table.getAttribute('id');

    element.addEventListener('click', () => {
      const clickedId = element.dataset.id;

      if (!selectedIds[tableId]) {
        selectedIds[tableId] = [];
      }

      const index = selectedIds[tableId].indexOf(clickedId);

      if (element.checked) {
        if (index < 0) {
          selectedIds[tableId].push(clickedId);
        }
      } else {
        if (index >= 0) {
          selectedIds[tableId].splice(index, 1);
        }
      }

      updateSelected(table);
    });
  });

  selectAllElements.forEach((selectAllElement) => {
    const table = selectAllElement.closest('table');
    const tableId = table.getAttribute('id');
    const tCbElements = table.querySelectorAll('.table-checkbox');

    selectAllElement.addEventListener('click', () => {
      if (selectedIds[tableId] && selectedIds[tableId].length == tCbElements.length) {
        selectedIds[tableId] = [];
        changeTableCheckboxStatus(tCbElements, false);
      } else {
        selectedIds[tableId] = [...tCbElements].map((element) => element.dataset.id);
        changeTableCheckboxStatus(tCbElements, true);
      }

      updateSelected(table);
    });
  });
};

export default initCheckboxes;
