import tippy from 'tippy.js';
import { Tooltip } from 'bootstrap';

const initTooltips = () => {
  tippy('[data-tippy-content]:not([data-tippy-content=""])');

  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  tooltipTriggerList.forEach((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl));
};

export default initTooltips;
