import tippy from 'tippy.js';
import ClipboardJS from 'clipboard';

const initCopyRecoveryCodes = () => {
  tippy('.btn-copy-clipboard', { trigger: 'click' });

  new ClipboardJS('.btn-copy-clipboard');
};

export default initCopyRecoveryCodes;
