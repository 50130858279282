function showMenu() {
  document.querySelector('.topnav-mobile-toggle').classList.add('expanded');
  document.querySelector('.mobile-menu').classList.add('expanded');
  document.querySelector('.mobile-menu-backdrop').classList.add('opened');
  document.querySelector('html').classList.add('mobile-menu-opened');
}

function hideMenu() {
  document.querySelector('.topnav-mobile-toggle').classList.remove('expanded');
  document.querySelector('.mobile-menu').classList.remove('expanded');
  document.querySelector('.mobile-menu-backdrop').classList.remove('opened');
  document.querySelector('html').classList.remove('mobile-menu-opened');
}

const initTopnavMobile = () => {
  const element = document.querySelector('.topnav-mobile-toggle');

  if (!element) {
    return;
  }

  element.addEventListener('click', () => {
    if (element.classList.contains('expanded')) {
      hideMenu();
    } else {
      showMenu();
    }
  });

  document.querySelector('.mobile-menu-backdrop').addEventListener('click', function () {
    hideMenu();
  });
};

export default initTopnavMobile;
